import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import StatsForm from '../components/statsForm'
import SubFooter from '../components/subFooter'
import Link from '../components/anchor'

class FreeUrlShortener extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false,
      variant: 1
    }
  }

  componentDidMount () {
    window.dataLayer = window.dataLayer || []
    function gtag () { window.dataLayer.push(arguments) }
    gtag('js', new Date())

    gtag('config', 'UA-68215349-1', { 'optimize_id': 'GTM-W5PQBDJ' })
    const that = this
    function implementManyExperiments (value) {
      that.setState({
        variant: value
      })
    }
    gtag('event', 'optimize.callback', {
      callback: implementManyExperiments
    })
  }

  renderBanner () {
    return (
      <div className='container'>
        <div className='rebrand-link-block'>
          <div className='div_form_horizontal_form banner'>
            <div
              className='rebrand-link-limit-reached-banner-container'
            >
              <div className='rebrand-link-limit-reached-banner-copy'>
                <img
                  className='rebrand-link-limit-reached-banner-ill w-hidden-small'
                  src='../images/ill-upgrade.svg'
                  width={72}
                  height={72}
                  alt='upgrade'
                />
                <div className='titles'>
                  <h3 className='w-hidden-tiny w-hidden-small'>
                    Want more? Try Premium Features!
                  </h3>
                  <h3 className='w-hidden-medium w-hidden-main'>
                    Want more?
                    <br />
                    Try Premium Features!
                  </h3>
                  <h5 className='w-hidden-tiny w-hidden-small cta-description'>
                    Branded short URLs, detailed analytics, UTM builder,
                    QR codes, powerful dashboard and much more. Only $9/month.
                  </h5>
                  <h5 className='w-hidden-medium w-hidden-main cta-description'>
                    Branded short URLs, detailed analytics, UTM builder,
                    QR codes, powerful dashboard and much more. Only $9/month.
                  </h5>
                </div>
              </div>
              <div className='rebrand-link-limit-reached-banner-cta'>
                <div className='flex-column flex-row-mobile center-xs'>
                  <Link
                    href='https://httpslink.com/srt8'
                    target='_blank'
                    className='btn-secondary block-xs'
                  >
                    Go Premium
                  </Link>
                  <div>
                    <span className='font-small'>&nbsp;or&nbsp;</span>
                    <Link
                      href='https://httpslink.com/qpin'
                      target='_blank'
                      className='signup-btn-cta m-t-12'
                    >
                      sign up free
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render () {
    const { isOpen } = this.state
    return (
      <Layout
        type='empty'
        noMeta
      >
        <Helmet bodyAttributes={{
          class: 'body-no-padding'
        }}
        >
          <title>Track your short URLs</title>
          <meta
            content='The perfect free URL shortener for transforming long, ugly links into nice, memorable and trackable short URLs. Use it to shorten links'
            name='description'
          />
          <meta
            content='Free URL Shortener'
            property='og:title'
          />
          <meta
            content='RB.GY - Create short and memorable links for free'
            property='og:description'
          />
          <meta property='og:image' content='https://free-url-shortener.rb.gy/open-graph.png' />
          <meta property='og:url' content='https://free-url-shortener.rb.gy' />
          <meta property='og:type' content='website' />
          <meta content='summary' name='twitter:card' />
          <meta content='width=device-width, initial-scale=1' name='viewport' />
          <link href='images/rebrandly-webclip.png' rel='apple-touch-icon' />

          <style>
            {`.freetooltip {
              position: relative;
          }
          
          .freetooltip .tooltiptext {
              visibility: hidden;
              /* min-width: 260px; */
              background-color: #fff;
              border: 1px solid #E8E9EA;
              box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
              font-size: 15px;
              color: #212732;
              text-align: left;
              border-radius: 6px;
              padding: 6px 12px;
              /* Position the tooltip */
              position: absolute;
              z-index: 1;
              bottom: 100%;
              left: 20%;
              margin-left: -130px;
              margin-bottom: -1px;
          }
          
          @media only screen and (max-width: 1450px) {
              .freetooltip .tooltiptext {
                  visibility: hidden;
                  /* min-width: 260px; */
                  background-color: #fff;
                  border: 1px solid #E8E9EA;
                  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
                  font-size: 15px;
                  color: #212732;
                  text-align: left;
                  border-radius: 6px;
                  padding: 6px 12px;
                  /* Position the tooltip */
                  position: absolute;
                  z-index: 1;
                  bottom: 100%;
                  left: 100%;
                  margin-left: -100%;
              }
          }
          
          @media only screen and (max-width: 991px) {
              .freetooltip .tooltiptext {
                  visibility: hidden;
                  min-width: 260px;
                  background-color: #fff;
                  border: 1px solid #E8E9EA;
                  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
                  font-size: 15px;
                  color: #212732;
                  text-align: left;
                  border-radius: 6px;
                  padding: 6px 12px;
                  /* Position the tooltip */
                  position: absolute;
                  z-index: 1;
                  bottom: 100%;
                  left: 50%;
                  margin-left: -130px;
                  margin-bottom: 4px;
              }
          }
          
          .freetooltip:hover .tooltiptext {
              visibility: visible;
          }`}
          </style>
        </Helmet>
        <StatsForm
          variant={this.state.variant}
          className='section free-url-shortener'
          title={
            <h1 className='white-text'>Track your short URLs</h1>
          }
          description={
            <div className='terms-copy terms-copy-variant w-hidden-small'>Enter the URL to find out how many clicks it has received so far.
              Example: rb.gy/pjzjsl<br />
            </div>
          }
          placeholder='Enter your rb.gy link here'
        />
        <div className='section zebra'>
          <div className='container'>
            <div className='container-small-inside-center-720'>
              <h1 className='new_h1_dark no-margin'>
                <strong className='new_h2_dark'>How to track your links</strong>
              </h1>
              <br />
              <p className='paragraph_dark_center'>
                Once you have created and shared the short URL,{' '}&nbsp;
                <strong>just add symbol “+” to the end</strong> of it and you will get
                real-time analytics showing the numbers of clicks received
                from your shortened URL.<br />Click here to see an example:{' '}
                <a target='_blank' href='https://rb.gy/pjzjsl+'>
                  rb.gy/pjzjsl+
                </a>
              </p>
            </div>
          </div>
          {this.renderBanner()}
        </div>
        <a className='link-footer alternative' href='https://rebrandly.com' target='_blank'>Powered by <img src='/images/url-shortener.svg' alt='Url shortener' className='logo-header' /></a>
        <SubFooter />
      </Layout>
    )
  }
}

export default FreeUrlShortener
